<template>
  <el-dialog
    append-to-body
    width="800px"
    :title="`${isChange ? '修改' : '新增'}`"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form
      v-loading="loading"
      element-loading-text="加载中…"
      ref='elFormDom'
      label-width='110px'
      label-position='right'
      :model='form.data'
      :rules='form.rules'
    >
      <el-form-item prop='name' label='门店名称'>
        <el-input v-model="form.data.name" placeholder="请输入" clearable></el-input>
      </el-form-item>
      <el-form-item label="所在地区" prop="area_arr">
        <el-cascader
          :popper-append-to-body="false"
          popper-class="areaCascaderWrap"
          style="width:100%;"
          v-model="form.data.area_arr"
          :options="areaArr"
          >
        </el-cascader>
      </el-form-item>
      <el-form-item
        label="详细地址"
        :rules="{
          required: true,
          message: '请输入详细地址',
        }"
        prop="address"
      >
        <el-input v-model="form.data.address" placeholder="请输入详细地址" clearable></el-input>
      </el-form-item>
      <el-form-item
        label="定位"
        :rules="{
          required: true,
          message: '请选择',
        }"
        prop="dropAddress"
      >
        <div class="map" id="mapDiv"></div>
        <el-autocomplete
          v-model="form.data.dropAddress"
          :fetch-suggestions="querySearchAsync"
          placeholder="请输入关键词"
          @select="change"
          style="width:650px;"
        ></el-autocomplete>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click='toggle(false)'>取消</el-button>
        <el-button type="primary" :loading="form.loading" @click='confirm'>确定</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import common from '@/util'
  export default {
    name: 'ShopEdit',
    props: ['areaArr'],
    data() {
      return {
        token: '',
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            name: '',
            phone: '',
            address: '',
            dropAddress: '',
            longitude: '',
            latitude: '',
            province: '',
            city: '',
            area: '',
            area_arr: [],
          },
          rules: {
            name: [{ required: true, message:'必填项', trigger: 'change' }],
            area_arr: [{ required: true, message: '必填项', trigger: 'change' }],
          }
        },
        zoom: 19,
        location: {lng: 0, lat: 0},
        map: null,
        localsearch: null,
        polygonPath: [],

        btnloading: false,
        addUrl: "",
        options: [],
        options1: [],
      }
    },
    methods: {
      // 获取详情
      getDetail(row) {
        this.tobmap()
        if(!!row.id) {
          this.isChange = true;
          this.$nextTick(() => {
            common.deepClone(this.form.data, row);
            this.form.data.area_arr = [row.province,row.city,row.area];
            this.form.data.longitude = row.longitude;
            this.form.data.latitude = row.latitude;
            this.form.data.address = row.address;
          })
        }
      },
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        this.form.data.longitude = '';
        this.form.data.latitude = '';
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 提交
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
            let _area_arr = this.form.data.area_arr;
            this.form.data.province = _area_arr[0];
            this.form.data.city = _area_arr[1];
            this.form.data.area = _area_arr[2] || 0;
            let _params = { ...this.form.data}
            delete _params.area_arr
            let apiUrl = !this.isChange ? '/admin/flex_store/add' : '/admin/flex_store/edit'
            console.log(_params)
            // return
            this.form.loading = true;
            this.$http.post(apiUrl, _params).then(res => {
              if(res.code === 1) {
                this.toggle(false);
                this.$emit('refresh')
              } else {
                this.$message.error(res.msg)
              }
            }).finally(() => {
              this.form.loading = false;
            })
          }
        })
      },
      // 搜索框的搜索事件
      confirmAddress(query) {
        this.localsearch && query && this.localsearch.search(query, 7);
      },
      querySearchAsync(queryString, cb) {
        console.log(queryString, "queryString", this);
        this.confirmAddress(queryString);
        setTimeout(() => {
          cb(this.options1);
        }, 500);
      },
      updatePolygonPath(e) {
        this.polygonPath = e.target.getPath();
      },

      tobmap() {
        console.log(this.map)
        !this.map &&
          !this.localsearch &&
          setTimeout(() => {
            this.map = new T.Map("mapDiv");
            //设置显示地图的中心点和级别
            this.map.centerAndZoom(
              new T.LngLat(
                this.form.data.longitude || 115.86086,
                this.form.data.latitude || 28.691483
              ),
              12
            );
            
            //创建面对象
            this.map.disableDoubleClickZoom();
            this.map.enableDrag();
            var config = {
              pageCapacity: 10, //每页显示的数量
              onSearchComplete: (result) => {
                //根据返回类型解析搜索结果
                console.log(result.getResultType());
                switch (parseInt(result.getResultType())) {
                  case 1:
                    //解析点数据结果
                    if (result.getPois()) {
                      this.options1 = result.getPois().map((item) => {
                        return {
                          value: item.name,
                          address: item.address,
                          lonlat: item.lonlat,
                        };
                      });
                    } else {
                      this.options1 = [];
                    }
                    break;
                }
              },
            };
            this.map.addEventListener("click", (res) => {
              this.form.data.latitude = res.lnglat.lat;
              this.form.data.longitude = res.lnglat.lng;
              this.map.clearOverLays();
              let marker = new T.Marker(
                new T.LngLat(res.lnglat.lng, res.lnglat.lat)
              );
              //向地图上添加标注
              this.map.addOverLay(marker);
            });
            //创建搜索对象
            this.localsearch = new T.LocalSearch(this.map, config);
            //创建标注对象
            let marker = new T.Marker(
              new T.LngLat(
                this.form.data.longitude || 115.86086,
                this.form.data.latitude || 28.691483
              )
            );
            //向地图上添加标注
            this.map.addOverLay(marker);
            this.confirmAddress(this.form.data.dropAddress);
          }, 500);
        if (this.map && this.localsearch) {
          setTimeout(() => {
            this.map.centerAndZoom(
              new T.LngLat(
                this.form.data.longitude || 115.86086,
                this.form.data.latitude || 28.691483
              ),
              12
            );
            this.map.clearOverLays();
            let marker = new T.Marker(
              new T.LngLat(
                this.form.data.longitude || 115.86086,
                this.form.data.latitude || 28.691483
              )
            );
            //向地图上添加标注
            this.map.addOverLay(marker);
          }, 500);
        }
      },
      change(e) {
        if (e) {
          let Lng = e.lonlat.split(",")[0] * 1;
          let Lat = e.lonlat.split(",")[1] * 1;
          this.map.clearOverLays();
          this.map.panTo(new T.LngLat(Lng, Lat));
          //创建标注对象
          let marker = new T.Marker(new T.LngLat(Lng, Lat));
          //向地图上添加标注
          this.map.addOverLay(marker);
          this.form.data.latitude = Lat;
          this.form.data.longitude = Lng;
        }
      },
      
      
    }
  }
</script>

<style >
.areaCascaderWrap .el-cascader-menu:nth-of-type(1) .el-radio {
  display: none !important;
}
</style>
<style scoped>
.add_btn {
  height: 60px;
  padding: 0 30px;
  background: #fff;
  box-sizing: border-box;
}
.map {
  height: 400px;
  width: 100%;
}
.bm-view {
  height: 400px;
}
.elform ::v-deep .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.elform ::v-deep .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.elform ::v-deep .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.elform ::v-deep .avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.input {
  -webkit-appearance: none;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}
.flex-row {
  display: flex;
}
</style>
